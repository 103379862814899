import "./styles.css";
import { Canvas } from "@react-three/fiber";
import { useLoader } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { Suspense } from "react";
import { AmbientLight } from "three";
import { Html, useProgress } from '@react-three/drei'

function Loader() {
  const { progress } = useProgress()
  return <Html center>{progress.toPrecision(4)} % loaded</Html>
}

const Model = () => {
  const gltf = useLoader(GLTFLoader, "./models/scene.glb");
  return (
    <>
      <primitive object={gltf.scene} scale={1} />
    </>
  );
};

export const App = () => {
  return (
    <div className="App">
      <Canvas camera={{position: [0, 80, 40]}}>
        <Suspense fallback={<Loader/>}>
          <Model />
          <OrbitControls />
          <ambientLight intensity={2}/>
          <directionalLight intensity={10} position={[0, 100, -50]}/>
        </Suspense>
      </Canvas>
    </div>
  );
}
